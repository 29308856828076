import React from 'react';
import LogoMarketVista from './assets/icons/Logo_MarketVista_w.png';
import { ReactComponent as IconClose } from './assets/icons/close-icon.svg';

import "./App.css";

class App extends React.Component {
  state = {
    open: false
  }
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href="#">
              <img src={LogoMarketVista} alt="MarketVista Logo" className="logo" />
            </a>
          </div>

        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Launching MarketVista</h1>
            <div class="dot-flashing" />
            <p>Website coming soon. Please check back to know more.</p>
            <p>Connect with us via LinkedIn or E-Mail:</p>
          </div>
          <a href="https://www.linkedin.com/company/market-vista/">
            <div className="cta">Follow us on LinkedIn</div>
          </a>
          <a href="mailto:info@market-vista.com?subject=Request%20from%20Web">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <dialog open={this.state.open} className='impressum'>
          <div className='head'>
          <a onClick={() => this.setState({ open: !this.state.open })}>
            <IconClose className="icon" />
            <div className='text'>Close</div>
          </a>
          </div>
          
          <h1>Impressum</h1>
          <p><i>Angaben gemäß § 5 TMG.</i></p>
          <h2>Verantwortlich</h2>
          <p>Vowinckel Ventures UG (hatfungsbeschränkt)</p>
          <p>Schuckertstr. 9</p>
          <p>81379 München</p>
          <br />
          <p>Tel.: +49 15678 699 398</p>
          <a href="mailto:info@market-vista.com"><div>info@market-vista.com</div></a>
          <a href='https://www.market-vista.com'><div>www.market-vista.com</div></a>
          <br />
          <p>Amtsgericht München, HRB 294014</p>
          <p>Geschäftsführer: Konrad Stolte</p>
          <br />
          <h2>Social Media</h2>
          <p>Dieses Impressum gilt auch für unseren Unternehmensauftritt bei LinkedIn und Xing.</p>
        </dialog>
        <div className="footer">
          <span>© Vowinckel Ventures UG (hatfungsbeschränkt), 2024-2025. | <a className="underlined" onClick={() => this.setState({ open: !this.state.open })}>Impressum</a></span>
        </div>
      </div>
    );
  }
}

export default App;